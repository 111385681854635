import React, { FC } from 'react'
import { graphql, PageProps } from 'gatsby'

import { Layout } from '../components/Layout'
import { SEO } from '../components/SEO'

const Imprint: FC<PageProps<any>> = ({ data, location }) => {
  const { html, frontmatter } = data.allMarkdownRemark.edges[0].node

  return (
    <Layout location={location}>
      <SEO pageTitle={frontmatter.title} />
      <div dangerouslySetInnerHTML={{ __html: html }} />
    </Layout>
  )
}

export default Imprint

export const pageQuery = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/content/pages/imprint/" } }
    ) {
      edges {
        node {
          ...pageView
        }
      }
    }
  }
`
